<template>
  <AppHeader v-if="order" class="header">
    <BackButton :to="{ name: backButtonURL, params: { id } }" />
  </AppHeader>
  <ScreenLoading
    v-if="loading || processing"
    blur
    :title="$t('pages.review-customer-note.order-saved.title')"
  />
  <div data-e2e="picking-footer-border" class="fixed-footer-content page">
    <div class="bag">
      <img
        width="200"
        height="200"
        class="ut-img-contain"
        src="@/assets/images/complete-picking-order@2x.png"
        alt="Customer Note"
        data-e2e="checkout-image"
      />
    </div>
    <div class="note">
      <div class="note__header">
        <h1 class="note__title" data-e2e="note-title">
          {{ titleText }}
        </h1>
      </div>

      <div class="note__list">
        <div class="item">
          <div class="title">
            {{ $t('pages.review-customer-note.pickup-code.text') }}
          </div>
          <div class="description" data-e2e="pickup-code-label">
            {{ order?.pickupCode }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            {{ $t('pages.review-customer-note.total.text') }}
          </div>
          <div class="description" data-e2e="pickup-total-label">
            {{ countOfLabels }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            {{ $t('pages.review-customer-note.time.text') }}
          </div>
          <div class="description" data-e2e="order-pickup-time-label">
            {{ formattedTime }}
          </div>
        </div>
      </div>
    </div>
    <div data-e2e="picking-footer" class="fixed-footer-content__footer">
      <div class="fixed-footer-content__footer-button">
        <BaseButton
          :disabled="processing"
          btnOutline
          @click="pickingComplete"
          data-e2e="checkout-button"
        >
          {{ completeButtonText }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import BaseButton from '@/features/ui/components/Button.vue';
import { formatDateTime } from '@/composables/useDateFormat';
import { AppHeader, BackButton } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import { pickingCompletedSave, useOrder } from '@/features/review/composables';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export default defineComponent({
  components: {
    BaseButton,
    BackButton,
    AppHeader,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { order, loadOrder, loading } = useOrder();
    const { startTracking, stopTracking } = usePerformanceTracker();

    onBeforeMount(async () => {
      await loadOrder(props.id);
    });

    onMounted(() => {
      stopTracking(`printing-to-complete-picking-order-${props.id}`);
    });

    const formattedTime = computed(() => {
      return order.value && order.value.startTime
        ? formatDateTime(new Date(order.value.startTime))
        : '';
    });
    const router = useRouter();
    const processing = ref(false);

    const completeButtonText = computed(() => {
      return hasDeliveryUnit.value
        ? $t('pages.review-customer-note.finish-placing-label-button.text')
        : $t('pages.review-customer-note.finish-picking-button.text');
    });

    const backButtonURL = 'picking-labels';

    const titleText = computed(() =>
      hasDeliveryUnit.value
        ? $t('pages.review-customer-note.title.text')
        : $t('pages.review-customer-note.title-no-labels.text'),
    );

    const pickingComplete = async () => {
      if (order && order.value) {
        processing.value = true;

        if (hasDeliveryUnit.value) {
          processing.value = false;
          startTracking(
            `customer-note-to-bags-position-${props.id}`,
            PerformanceThresholdEnum.ROUTE_CHANGE,
          );
          await router.push('bag-position');
          return;
        }

        await pickingCompletedSave().save(order);
        processing.value = false;
      }
    };

    const hasDeliveryUnit = computed<boolean>(
      () => !!order.value?.deliveryUnits.length,
    );

    const countOfLabels = computed(() => order.value?.numberOfLabels ?? 0);

    return {
      order,
      pickingComplete,
      formattedTime,
      processing,
      loading,
      countOfLabels,
      hasDeliveryUnit,
      completeButtonText,
      titleText,
      backButtonURL,
    };
  },
});
</script>
<style lang="scss" scoped>
.page {
  justify-content: center;
}
.header {
  padding: 16px 16px 0;
}
.bag {
  margin: 0 auto;
  width: 180px;
}

.note {
  margin-top: 15px;
  text-align: left;
  color: $default-text-color;

  &__title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    text-align: center;
    line-height: 1.5em;
  }

  &__list {
    margin: 24px 0 16px;

    .item {
      display: grid;
      padding: 16px 8px;
      border-bottom: 0.5px solid rgba(182, 196, 223, 0.6);
      grid-template-columns: 116px 1fr;

      &--no-border {
        border-bottom: 0;
      }

      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.57em;
      }

      .description {
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.38em;
        letter-spacing: 0.07em;
      }
    }
  }
}
</style>
